
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import OrderSummary from "@/components/checkout-process/checkout/OrderSummary.vue";
import { IsLoggedIn, QuantityPerItem } from "@/mixins/utilities";
import { store } from "@/store";
import { Orders, Payments } from "@/network/api";
import { EftBankDetails, OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";
import CompleteOrderSummary from "@/components/checkout-process/payment-complete/CompleteOrderSummary.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { marked } from "marked";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";
import { TrackPurchase } from "@/mixins/gtag";
import { FacebookPurchaseEvent } from "@/seo/facebook-pixel-helper";

@Options({
  components: { Spinner, HeadingBanner, OrderSummary, CompleteOrderSummary, Breadcrumbs },
})
export default class EFTPaymentPage extends mixins(IsLoggedIn, QuantityPerItem, SetMetaData, TrackPurchase, FacebookPurchaseEvent) {
  orderId: any = "";
  orderRef: any = "";
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  loading = false;
  bankDetails: EftBankDetails = {
    bankTransferDetails: '',
    reference: ''
  };
  orderLoaded = false
  accessKey: string | undefined = undefined

  created() {
    this.orderLoaded = false
    if (this.$route.query.order) {
      this.orderId = this.$route.query.order;
    }

    if (this.$route.query.reference) {
      this.orderRef = this.$route.query.reference;
    }

    if (this.$route.query.accessKey) {
      this.accessKey = this.$route.query.accessKey as string;
    }

    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', `${this.orderRef ? ('Order #' + this.orderRef + ' Received')  : 'Order Received'} | `)

    this.loadInitialData();
  }

  mounted() {
    store.dispatch("checkout/refreshCart", {
      id: null,
      accessKey: this.accessKey || undefined
    });
  }

  get formattedBankDetails() {
    if (this.bankDetails.bankTransferDetails) {
      let markedDetails = marked(this.bankDetails.bankTransferDetails)
      markedDetails = markedDetails.replace('<p>', "");
      markedDetails = markedDetails.replace('</p>', "");
      markedDetails = markedDetails.replaceAll('<strong>', '<div class="d-flex align-items-baseline col-12 flex-wrap"><h6 class="bank-dets-headings m-0 me-2">');
      markedDetails = markedDetails.replaceAll('</strong>', '</h6>');
      markedDetails = markedDetails.replaceAll('<em>', '<p class="bank-dets-info m-0 mb-1">');
      markedDetails = markedDetails.replaceAll('</em>', '</p></div>');
      return markedDetails;
    }
    return "";
  }

  async loadInitialData() {
    this.orderLoaded = false
    if (this.orderId) {
      this.loading = true;
      await Orders.ordersGetOrderOrderIdGet(this.orderId, this.accessKey)
        .then((res) => {
          if (res.data.succeeded) {
            this.order = res.data.resultData as any;
            this.orderLoaded = true

            if(!this.$route.query.loaded) {
              this.trackPurchase(this.order)
              this.facebookPurchaseEvent({value: this.order.orderTotal, currency: this.order.currency.code})

              let newQueryParams:any = {
                ...this.$route.query,
                loaded: true,
              };
              this.$router.replace({ query: newQueryParams });
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.orderLoaded = true
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          console.log(error);
        });

      await Payments.paymentsGetEftBankDetailsIdGet(this.orderId, this.accessKey)
        .then((res) => {
          if (res.data.succeeded) {
            this.bankDetails = res.data.resultData as EftBankDetails;
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          console.log(error);
        });
      this.loading = false;
    }
  }
}
