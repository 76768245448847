
import { mixins, Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import countries from "@/assets/country-codes/countries.json";
import { DeliveryType, EftBankDetails, OrderCartDeliveryOptionViewModel, OrderCartViewModel, OrderStatus, UserAddressListViewModel } from "@/api-client";
import { Payments, UserAddresses } from "@/network/api";
import { store } from "@/store";
import StripeCheckoutForm from '@/components/checkout-process/checkout/StripeCheckoutForm.vue'
import { CalculatePrice, Currency, IsLoggedIn, ThousandSeparator, UploadPath, Newsletter } from "@/mixins/utilities";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import { marked } from "marked";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

@Options({
  components: { Form, Field, ErrorMessage, StripeCheckoutForm },
  props: {
    cart: { default: null },
    current: { default: "" },
    guestEmail: { default: "" },
    paymentProviders: { default: null },
    totalPrice: { default: 0 },
    deliveryDetails: { default: null },
    accessKey: {default: undefined}
  },
  emits: ["update", "toDetails", "toPack"],
})
export default class CheckoutUserDetailsComponent extends mixins(IsLoggedIn, UploadPath, Currency, ThousandSeparator, CalculatePrice, Newsletter) {
  currentStatus: number = STATUS_INITIAL;
  attachments: any = [];
  uploadedFiles: any;
  uploadError: any;
  countryCodes: any = countries;
  selectedDialCode = "";
  phoneNumber = "";
  socialEnabled = true;
  user = {
    email: "",
    password: "",
  };
  guestUser = {
    email: "",
  };
  cart: OrderCartViewModel = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    orderDocuments: [],
    isQuote: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    referenceNumber: '',
    deliveryAddress: {
      id: '',
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: '',
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: '',
      name: "",
      email: "",
      phoneNumber: "",
    },
    accessKey: '',
    allowedPaymentProviders: [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: [],
    storeId: ""
  };
  cartDetails: any = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    requiresCustomerApproval: true,
    orderDocuments: [],
    isQuote: false,
    deliveryAddress: {
      id: '',
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: '',
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: '',
      name: "",
      email: "",
      phoneNumber: "",
    },
    storeId: ""
  };
  isNewsletter = false;
  marketingOptIn = false;
  useDeliveryAsBilling = false;
  clonedBillingAddress: any = {
    id: '',
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    country: "",
    postalCode: "",
    additionalNotes: "",
    ponumber: "",
  };
  showOrderPackButtons = false;
  primaryAddress: UserAddressListViewModel = {
    id: "",
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    country: "",
    postalCode: "",
    isPrimary: true,
  };
  current = "";
  guestEmail = '';
  isCardPayment: 'card' | 'eft' | '' = ''
  bankDetails: EftBankDetails = {
    bankTransferDetails: '',
    reference: ''
  };
  terms = false;
  fileCount = 0;
  deliveryDetails: Array<OrderCartDeliveryOptionViewModel> = [];
  totalPrice = 0;
  deliveryAutoComplete = '';
  billingAutoComplete = '';
  accessKey: string | undefined = undefined;

  get sortedDialCodes() {
    return this.countryCodes.sort((a: any, b: any) => {
      return a.dialCode - b.dialCode;
    });
  }

  get totalOrderPrice() {
    if(this.deliveryDetails && this.deliveryDetails.length) {
      let subtotal = this.calculatePrice((this.totalPrice + this.deliveryDetails[0].deliveryCost), this.currency().pricingMultiplier);
      const total = (Number(subtotal) + this.cart.additionalCostsConvertedTotal) - Number(this.voucherFinalValue) - Number(this.cart.paymentsConvertedTotal)
      if(total > 0) {
        return total.toFixed(2);
      }
    }
    return 0;
  }

  get voucherFinalValue() {
    if (this.cart.voucher && (this.cartVoucherCriteria.includes('voucherValid'))) {
      if (this.cart.voucher.productVoucherAmount) {
        return Number(this.calculatePrice(this.cart.voucher.productVoucherAmount, 1)).toFixed(2);
      }
      else if (this.cart.voucher.deliveryVoucherAmount) {
        return Number(this.calculatePrice(this.cart.voucher.deliveryVoucherAmount, 1)).toFixed(2);
      }
      else if (this.cart.voucher.totalVoucherAmount) {
        return Number(this.calculatePrice(this.cart.voucher.totalVoucherAmount, 1)).toFixed(2);
      }
      return 0;
    }
    return 0;
  }

  get subTotalOrderPrice() {
    const subtotal = this.calculatePrice(this.totalPrice, this.currency().pricingMultiplier)
    const total = Number(subtotal) + this.cart.additionalCostsConvertedTotal;
    return total;
  }

  get cartVoucherCriteria() {
    if(this.cart.voucher) {
      let criteria: Array<any> = [];
      if(this.cart.voucher.minimumCartValue && this.subTotalOrderPrice) {
        const valueReached = (+this.subTotalOrderPrice - +this.calculatePrice(this.cart.voucher.minimumCartValue, this.currency().pricingMultiplier)) >= 0;

        if (valueReached) {
          criteria.push('cartValueValid')
        } else {
          criteria.push('cartValueNotValid')
        }
      }

      if(this.cart.voucher.startDate && this.cart.voucher.endDate) {
        const now = new Date();
        const start = new Date(this.cart.voucher.startDate);
        const end = new Date(this.cart.voucher.endDate);

        if (now > start && now < end) {
          criteria.push('cartDateValid')
        } else {
          criteria.push('cartDateNotValid')
        }
      }

      if(!criteria.includes('cartValueNotValid') && !criteria.includes('cartDateNotValid') ){
        criteria.push('voucherValid')
      }

      return criteria
    }
    return ['noVoucherApplied']
  }

  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  get userProfile() {
    return store.getters["user/profile"];
  }

  get delivery() {
    return this.cartDetails.deliveryAddress;
  }

  get isMarketingOptOut() {
    return !this.cart.marketingOptIn;
  }

  created() {
    this.fetchUserAddresses().then(() => {
      this.setDetails();
    });

    this.$watch("cart", () => {
      if (this.current === "details") {
        this.setDetails();
      }
    });

    this.$watch("cart.marketingOptIn", () => {
      this.setDetails();
    });

    this.$watch("deliveryEqualsBilling", () => {
      if(!this.useDeliveryAsBilling) {
        if(this.deliveryEqualsBilling) {
          this.useDeliveryAsBilling = true
        }
      }
    })

    this.$watch("useDeliveryAsBilling", () => {
      if(this.useDeliveryAsBilling) {
        self.cartDetails.billingAddress = { ...self.cartDetails.deliveryAddress };
      }
    })

    let self = this
    this.$watch('delivery', function handler(val:any) {
        if (self.useDeliveryAsBilling) {
          self.cartDetails.billingAddress = { ...self.cartDetails.deliveryAddress };
        }
      },
      {deep: true}
    )

    this.$watch("cart.billingAddress", () => {
      if (this.current === "details") {
        this.setDetails();
      }
    });

    this.$watch("isCardPayment", () => {
      if(this.isCardPayment === 'eft') {
          Payments.paymentsGetEftBankDetailsIdGet(this.cart.id, this.accessKey)
          .then((res) => {
            if(res.data.succeeded) {
              this.bankDetails = res.data.resultData as EftBankDetails
            }
          })
          .catch((error) => {
        console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
        }
    })
  }

  handlePaymentType({target}:any) {
    this.isCardPayment = target.value;
  }

  handleAttachmentName(attachment:any) {
    let arr = attachment.split('/');
    return arr[arr.length - 1];
  }

  get deliveryEqualsBilling() {
    if(this.cartDetails.deliveryAddress && this.cartDetails.billingAddress) {
      if(!this.cartDetails.deliveryAddress.addressLine1
      || !this.cartDetails.deliveryAddress.area
      || !this.cartDetails.deliveryAddress.country
      || !this.cartDetails.deliveryAddress.postalCode) {
        return false;
      }
      // compare delivery address and billing address
      return (this.cartDetails.deliveryAddress.addressLine1 === this.cartDetails.billingAddress.addressLine1) &&
        (this.cartDetails.deliveryAddress.addressLine2 === this.cartDetails.billingAddress.addressLine2) &&
        (this.cartDetails.deliveryAddress.area === this.cartDetails.billingAddress.area) &&
        (this.cartDetails.deliveryAddress.country === this.cartDetails.billingAddress.country) &&
        (this.cartDetails.deliveryAddress.postalCode === this.cartDetails.billingAddress.postalCode);
    }
    return false;
  }

  get formattedBankDetails() {
    if (this.bankDetails.bankTransferDetails) {
      let markedDetails = marked(this.bankDetails.bankTransferDetails)
      markedDetails = markedDetails.replace('<p>', "");
      markedDetails = markedDetails.replace('</p>', "");
      markedDetails = markedDetails.replaceAll('<strong>', '<div class="d-flex mb-1 align-items-baseline flex-wrap"><h6 class="bank-dets-headings m-0 me-2">');
      markedDetails = markedDetails.replaceAll('</strong>', '</h6>');
      markedDetails = markedDetails.replaceAll('<em>', '<p class="bank-dets-info m-0 mb-1">');
      markedDetails = markedDetails.replaceAll('</em>', '</p></div>');

      return markedDetails;
    }
    return "";
  }

  deleteItem(attachment: any) {
    let refs = this.$refs as any;
    refs.file.value = ""
    this.attachments.splice(this.attachments.indexOf(attachment), 1);

  }

  filesChange(fileList: any) {
    let files = [...fileList]
    if (!files.length) return;

    files.forEach((file: any) => {
      const formData = new FormData();
      formData.append("file", file);
      this.save(formData);
    });
  }

  save(formData: any) {
    // upload data to the server
    this.currentStatus = STATUS_SAVING;

    this.upload(formData)
      .then((x: any) => {
        this.attachments.push(x);
        this.currentStatus = STATUS_INITIAL;
      })
      .catch((err: any) => {
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
  }

  upload(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }

  onInvalidSubmit({ values, errors, results }:any) {
    let notValidValues = [] as Array<any>;
    for(let error in errors){
      let value = error.split(' is not valid.')
      notValidValues.push(value[0]);
    }

    let message = `Please complete: ${notValidValues.join(', ')}`;

    this.$notify({type: 'error', text: message});
    }

  async fetchUserAddresses() {
    if (this.isLoggedIn) {
      await UserAddresses.userAddressesGet(1, 999999)
      .then((res) => {
        if (res.data.succeeded) {
          let addresses = res.data.resultData!.items as Array<UserAddressListViewModel>;
          if (addresses.length) {
            this.primaryAddress = addresses.find((address: UserAddressListViewModel) => {
              return address.isPrimary;
            }) as UserAddressListViewModel;
          }
        }
      })
      .catch((error) => {
        console.log(error)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  setPlace(place: any, type: any) {
    let streetNum = "";
    let streetName = "";
    let subPremise = "";
    this.cartDetails[type].addressLine1 = ''
    this.cartDetails[type].addressLine2 = ''
    this.cartDetails[type].area = ''
    this.cartDetails[type].country = ''
    this.cartDetails[type].postalCode = ''

    place.address_components.forEach((component: any) => {
      if (component.types.includes("street_number")) {
        streetNum = component.long_name;
      }
      if (component.types.includes("route")) {
        streetName = component.long_name;
      }
      if (streetNum || streetName) {
        this.cartDetails[type].addressLine1 = `${streetNum}${streetNum && " "}${streetName}${subPremise && ", "}${subPremise}`;
      }
      if (component.types.includes("locality")) {
        this.cartDetails[type].addressLine2 = component.long_name;
      } else if (component.types.includes("postal_town")) {
        this.cartDetails[type].addressLine2 = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        this.cartDetails[type].area = component.long_name;
      } else if(component.types.includes("administrative_area_level_2")) {
        this.cartDetails[type].area = component.long_name;
      }
      if (component.types.includes("country")) {
        this.cartDetails[type].country = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        this.cartDetails[type].postalCode = component.long_name;
      }
    });
  }

  async setDetails() {
    if (this.cart.id !== "") {
      this.cartDetails = { ...this.cart };
      await this.fetchUserAddresses()
        if (!this.cartDetails.deliveryAddress) {
          if (this.primaryAddress) {
            this.cartDetails.deliveryAddress = {
              ...this.primaryAddress,
            };
          } else {
            this.cartDetails.deliveryAddress = {
              companyName: "",
              addressLine1: "",
              addressLine2: "",
              area: "",
              country: "",
              postalCode: "",
              additionalNotes: "",
              ponumber: "",
            };
          }
        }

        if (!this.cartDetails.billingAddress) {
          this.cartDetails.billingAddress = {
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            area: "",
            country: "",
            postalCode: "",
            additionalNotes: "",
            ponumber: "",
          };
        }

        if(this.deliveryEqualsBilling) {
          this.useDeliveryAsBilling = true
        } else {
          this.useDeliveryAsBilling = false
        }

        if (this.useDeliveryAsBilling) {
          this.cartDetails.billingAddress = { ...this.cartDetails.deliveryAddress };
        } 

      if (this.userProfile) {
        this.cartDetails.customerContact = {
          name: this.userProfile.displayName,
          email: this.userProfile.email,
          phoneNumber: this.userProfile.phoneNumber,
        };
      } else {
        this.cartDetails.customerContact = {
          name: this.cartDetails.customerContact?.name || '',
          email: this.cartDetails.customerContact?.email || this.guestEmail || '',
          phoneNumber: this.cartDetails.customerContact?.phoneNumber || '',
        };
      }
      if (this.cartDetails.customerContact.phoneNumber && (this.cartDetails.customerContact.phoneNumber !== "")) {
        let array = this.cartDetails.customerContact.phoneNumber.split("-");
        if (array.length >= 2) {
          this.selectedDialCode = array[0];
          array.shift()
          this.phoneNumber = array.join('-');
        }
      }
      if (this.selectedDialCode == "") {
        let selectedCountry = store.getters["location/country"];

        if (selectedCountry) {
          let matchedCountry = this.countryCodes.find((country: any) => {
            return selectedCountry.code === country.isoCode;
          });

          if (matchedCountry) {
            this.selectedDialCode = matchedCountry.dialCode;
          }
        }

        if (!this.selectedDialCode) {
          this.selectedDialCode = "+1";
        }
      }

      if(this.cartDetails.orderDocuments.length) {
        this.attachments = []
        this.cartDetails.orderDocuments.forEach((doc: any) => {
          this.attachments.push(doc.documentUrl);
        });
      }
    }
  }

  handleNext() {
    let numberWithCode = `${this.selectedDialCode}-${this.phoneNumber}`;
    let detailsToSubmit = { ...this.cartDetails };
    detailsToSubmit.billingAddress.ponumber = detailsToSubmit.deliveryAddress.ponumber;
    if (this.isNewsletter) {
      this.newsletterSubscribe(this.cartDetails.customerContact.email, 'Checkout process newsletter opt-in');
    }
    if (this.useDeliveryAsBilling) {
      detailsToSubmit.billingAddress = { ...this.cartDetails.deliveryAddress };
    }
    detailsToSubmit.customerContact.phoneNumber = numberWithCode;
    detailsToSubmit.orderDocuments = []
    
    if(this.attachments.length) {
      this.attachments.forEach((doc:any) => {
        detailsToSubmit.orderDocuments.push({documentUrl: doc});
      });
    }
    if((detailsToSubmit.customerContact.email === '') && (this.guestEmail !== '')) {
      detailsToSubmit.customerContact.email = this.guestEmail;
    }
    
    // TODO improve scroll to top
    window.scrollTo(0, 0);
    this.$emit("update", detailsToSubmit, 'order-pack');
    // this.$emit("toPack");
    this.showOrderPackButtons = true;
  }

  updateDetails(value: boolean) {
    this.setDetails()
    let detailsToSubmit = { ...this.cartDetails };
    detailsToSubmit.requiresCustomerApproval = value ? true : false;
    
    //window.scrollTo(0, 0);
    this.$emit("update", detailsToSubmit, 'payment');
    
  }

  noPaymentOutstandingCheckout() {
    this.$emit('loading', true)
    Payments.paymentsCompleteCheckoutNoOutstandingPaymentIdPut(this.cart.id, this.accessKey)
      .then((res) => {
        if(res.data.succeeded) {
          this.$router.push({name: 'PaymentComplete', params: { id: this.cart.id, accessKey: this.accessKey }, query: { balance: 'none' }})  
        }
        this.$emit('loading', false)
      })
      .catch((error) => {
        console.log(error)
        this.$emit('loading', false)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  EFTCheckout() {
    this.$emit('loading', true)
    Payments.paymentsCompleteCheckoutEftIdPut(this.cart.id, this.accessKey)
      .then((res) => {
        if(res.data.succeeded) {         
          this.$router.push({name: 'EFTPaymentPage', query: {order: this.cart.id, reference: this.bankDetails.reference, accessKey: this.accessKey}})  
        }
        this.$emit('loading', false)
      })
      .catch((error) => {
        console.log(error)
        this.$emit('loading', false)
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }
}
