
import { CalculatePrice, FormatDate, ThousandSeparator } from "@/mixins/utilities";
import { mixins, Options } from "vue-class-component";
import ProfileProductTile from "@/components/profile/ProfileProductTile.vue";
import { CustomizedProductCreateUpdateModel, OrderCartItemQuantityCreateUpdateModel, OrderHistoryViewModel, OrderPublicStatus } from "@/api-client";
import { CustomizedProducts } from "@/network/api";
import { store } from "@/store";
import { ScrollTop } from "@/mixins/utilities";

@Options({
  components: { ProfileProductTile },
  props: {
    order: { default: null },
    showButtons: { default: true },
    pageName: { default: "Profile" },
  },
  emits: ["showDetails", "showInvoice", "loading"],
})
export default class OrderListInfoTile extends mixins(CalculatePrice, ThousandSeparator, FormatDate, ScrollTop) {
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    updatedDeliveryDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  pageName = "Profile";
  routeTo = {
    profile: {
      details: {
        name: "Profile",
        query: {
          section: "order-history",
          order: undefined as string | undefined,
        },
      },
      invoice: {
        name: "Profile",
        query: {
          section: "order-history",
          invoice: undefined as string | undefined,
        },
      },
    },
    checkorderstatus: {
      details: {
        name: "CheckOrderStatus",
        query: {
          order: undefined as string | undefined,
          postal: undefined as string | undefined,
          details: 'show'
        },
      },
      invoice: {
        name: "CheckOrderStatus",
        query: {
          order: undefined as string | undefined,
          postal: undefined as string | undefined,
          invoice: undefined as string | undefined,
        },
      },
    },
  };

  created() {
      this.routeTo = {
      profile: {
        details: {
          name: "Profile",
          query: {
            section: "order-history",
            order: this.order.referenceNumber,
          },
        },
        invoice: {
          name: "Profile",
          query: {
            section: "order-history",
            invoice: this.order.referenceNumber,
          },
        },
      },
      checkorderstatus: {
        details: {
          name: "CheckOrderStatus",
          query: {
            order: this.order.referenceNumber,
            postal: (this.$route.query.postal as string) || undefined,
            details: 'show'
          },
        },
        invoice: {
          name: "CheckOrderStatus",
          query: {
            order: this.order.referenceNumber,
            postal: (this.$route.query.postal as string) || undefined,
            invoice: this.order.referenceNumber,
          },
        },
      }
    }
  }

  topAnchor() {
    this.scrollTop(`.${this.pageName.toLowerCase()}-content-container`, false);
  }

  calculatedPrice(price: number) {
    if (this.orderCurrency && price === 0) {
      return (0).toFixed(this.orderCurrency.decimalPlaces);
    } else if (this.orderCurrency && price) {
      return this.calculatePrice(price, this.orderCurrency.pricingMultiplier, this.orderCurrency.decimalPlaces);
    }
    return "Error";
  }

  get orderCurrency() {
    if (this.order.id) {
      return this.order.currency;
    }
    return null;
  }

  get voucherValue() {
    if (this.order.voucher && this.orderCurrency) {
      if (this.order.voucher.voucherTypeId === "Absolute") {
        return Number(this.calculatePrice(this.order.voucher.value, this.orderCurrency.pricingMultiplier));
      } else if (this.order.voucher.voucherTypeId === "Percentage") {
        const finalDiscount = this.order.orderTotal * this.order.voucher.value;
        const max = this.order.voucher.maximumValue ?
          Number(this.calculatePrice(this.order.voucher.maximumValue, this.orderCurrency.pricingMultiplier)) : 0;
          
        if (max != 0 && finalDiscount > max) {
          return max
        }
        return Number(finalDiscount);
      }
    }
    return 0;
  }

  async orderAgain() {
    this.$emit("loading", true);
    let count = 0;

    // Process each order item sequentially
    for (const item of this.order.orderItems) {
        try {
            let product = item.customizedProduct;
            let newCustomizedProductId = "";
            let newOrderItem = {
              productId: product.productId,
              previewImageUrl: product.previewImageUrl,
              backPreviewImageUrl: product.backPreviewImageUrl,
              title: product.title,
              style: { ...product.style },
              design: { ...product.design },
              layers: [...product.layers],
              items: [...product.items],
              extras: [...product.extras],
              isForQuote: false,
            } as CustomizedProductCreateUpdateModel;

            newOrderItem.items.forEach((item) => {
              delete item.id;
            });

            const res = await CustomizedProducts.customizedProductsPost(newOrderItem);
            if (res.data.succeeded) {
              newCustomizedProductId = res.data.resultData;
              await this.addToCart(newCustomizedProductId, item.sizes);
              count += 1;
              console.log(count);
              if (count === this.order.orderItems.length) {
                let id = store.getters["checkout/cartId"];
                if (id) {
                  this.$emit("loading", false);
                  this.$router.push({ name: "Cart", params: { id: id } });
                } else {
                  this.$notify({ type: "error", text: "An unknown error occurred.", ignoreDuplicates: true, duration: -1 });
                }
              }
            } else {
              throw new Error('Product post failed');
            }
        } catch (error) {
          console.log(error);
          this.$notify({ type: "error", text: "An unknown error occurred.", ignoreDuplicates: true, duration: -1 });
        }
    }

    // Ensure the loading indicator is turned off if all operations are done
    this.$emit("loading", false);
}


  async addToCart(customizedProductId: string, quantities: Array<OrderCartItemQuantityCreateUpdateModel>) {
    if (customizedProductId) {
      await store
        .dispatch("checkout/getCart")
        .then(async (cartId) => {
          await store.dispatch("checkout/addCartItem", {
            quantities: quantities,
            customizedProductId: customizedProductId,
          });
          // this.$notify({ type: "success", text: 'Cart updated.', ignoreDuplicates: true });
        })
        .catch((error) => {
          console.log(error);
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
          console.error("add to cart failed", error);
        });
    }
  }
}
