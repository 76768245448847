
import { CustomizedProductSizeOptionsViewModel, OrderCartItemQuantityAdjustmentViewModel, OrderCartItemQuantityViewModel, OrderCartItemViewModel, OrderCartItemQuantityCreateUpdateModel, DeliveryType, CustomizedProductPriceViewModelApplicationResultResultData, ProductStyleViewModel, OrderCartViewModel, OrderStatus } from "@/api-client";
import { CalculatePrice, Country, Currency, FormatDate, QuantityPerItem, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import { CartItems, CustomizedProducts, Products } from "@/network/api";
import { mixins, Options, Vue } from "vue-class-component";
import SizeGuidelines from "@/components/products/SizeGuidelines.vue";
import Spinner from "@/components/spinner/Spinner.vue";
import NamesAndNumbers from "@/components/checkout-process/cart/NamesAndNumbers.vue";

@Options({
  components: { SizeGuidelines, Spinner, NamesAndNumbers },
  props: {
    show: Boolean,
    product: { default: null },
    quantity: { default: 1 },
    cart: { default: null },
    inCart: { default: false },
    slug: { default: "" },
    id: { default: "" },
  },
  emits: ["close", "select", "checkout", "update"],
})
export default class SizeNameSelector extends mixins(UploadPath, QuantityPerItem, Country, Currency, CalculatePrice, ThousandSeparator, FormatDate) {
  show = false;
  allowClickOutside = false;
  timeout: any;
  product: OrderCartItemViewModel = {
    id: "",
    price: 0,
    totalPrice: 0,
    voucherDiscount: 0,
    discountedTotalPrice: 0,
    orderId: "",
    customizedProduct: {
      id: "",
      productId: "",
      productSlug: "",
      title: "",
      lastModifiedDate: "",
      name: "",
      code: "",
      previewImageUrl: "",
      canAddToStore: false,
      backPreviewImageUrl: "",
      customerCanUpdate: false,
      adminCanUpdate: false,
      orderReferenceNumber: "",
      enquiryReferenceNumbers: [],
      orderId: "",
      minimumQuantity: 1,
      defaultQuantity: 1,
      style: {
        id: "",
        name: "",
        code: "",
        previewImageUrl: "",
        frontShadowMaskUrl: "",
        backShadowMaskUrl: "",
        manufacturerReference: "",
        factoryId: "",
      },
      design: {
        id: "",
        name: "",
        code: "",
        imageUrl: "",
      },
      layers: [],
      items: [],
      extras: [],
    },
    quantities: [],
  };
  selectedProduct: OrderCartItemViewModel = {
    id: "",
    price: 0,
    totalPrice: 0,
    voucherDiscount: 0,
    discountedTotalPrice: 0,
    orderId: "",
    customizedProduct: {
      id: "",
      productId: "",
      productSlug: "",
      title: "",
      lastModifiedDate: "",
      name: "",
      code: "",
      previewImageUrl: "",
      canAddToStore: false,
      backPreviewImageUrl: "",
      customerCanUpdate: false,
      adminCanUpdate: false,
      orderReferenceNumber: "",
      enquiryReferenceNumbers: [],
      orderId: "",
      minimumQuantity: 1,
      defaultQuantity: 1,
      style: {
        id: "",
        name: "",
        code: "",
        previewImageUrl: "",
        frontShadowMaskUrl: "",
        backShadowMaskUrl: "",
        manufacturerReference: "",
        factoryId: "",
      },
      design: {
        id: "",
        name: "",
        code: "",
        imageUrl: "",
      },
      layers: [],
      items: [],
      extras: [],
    },
    quantities: [],
  };
  quantity = 1;
  quantities: Array<OrderCartItemQuantityCreateUpdateModel> = [
    {
      quantity: 1,
      sizeId: "",
      adjustments: [], //adjustmentId strings
      extraCustomizations: [],
    },
  ];
  quantitiesArray: Array<any> = [
    {
      quantity: 1,
      selectedSizeGroup: {
        id: "",
        displayName: "",
        sizes: [],
      },
      selectedSize: {
        id: "",
        name: "",
      },
      selectedAdjustments: [],
      extraCustomizations: [],
    },
  ];
  productSizeInfo: CustomizedProductSizeOptionsViewModel = {
    sizeGroups: [],
    adjustmentTypes: [],
    extras: [],
  };
  inCart = false;
  showSizeInfo = false;
  showNamesNumbers = false;
  loading = false;
  slug = "";
  id = "";
  selectedRow: any = null;
  selectedRowIndex: any = null;
  newQuantity = 0;
  priceInfo: CustomizedProductPriceViewModelApplicationResultResultData = {
    price: 0,
    isTotalPrice: false,
    deliveryOptions: [
      {
        deliveryTypeId: DeliveryType.Standard,
        deliveryTypeName: "Standard",
        deliveryDays: 0,
        productionDays: 0,
        deliveryCost: 0,
        deliveryDelayDays: 0,
        productionDelayDays: 0
      },
    ],
  };
  costQuantity = 1;
  sizeBreakDownInfo: ProductStyleViewModel = {
    name: "",
    sizeGroups: [],
    sizes: [],
    adjustmentTypes: [],
    adjustments: [],
  };
  showSizeBreakdown = true;
  cart: OrderCartViewModel = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    additionalCostsConvertedTotal: 0,
    orderDocuments: [],
    isQuote: false,
    marketingOptIn: false,
    referenceNumber: "",
    deliveryAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    quoteMessage: "",
    accessKey: "",
    allowedPaymentProviders: [],
    voucher: null,
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: [],
    storeId: null,
  };
  editCustomer = false;
  showSizeError = false;

  get isStoreCustomer() {
    let prop = Object.prototype.hasOwnProperty.call(this.cart, "storeId");
    return prop && this.cart.storeId ? true : false;
  }

  created() {
    this.$watch("show", () => {
      this.showSizeError = false;
      if (this.show === true) {
        this.selectItem(this.product as any);
      } else {
        this.resetValues();
      }
    });

    this.$watch("showSizeInfo", () => {
      let hasProp = Object.prototype.hasOwnProperty.call(this.selectedProduct, "customizedProduct");
      let selected = this.selectedProduct as any;

      if (this.showSizeInfo && ((hasProp && selected.customizedProduct.style.id) || (!hasProp && selected.styleId))) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    });

    this.$watch("totalQuantity", () => {
      if (!this.inCart && !this.isStoreCustomer) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.determineCosts(this.product.id || this.product.customizedProduct.id);
        }, 400);
      }
    });
  }

  get totalQuantity() {
    let total = 0;
    this.quantitiesArray.forEach((quantity) => {
      total += quantity.quantity;
    });
    return total;
  }

  getSizeBreakdown(row: any) {
    const selectedSizeAttributes = row.selectedSizeGroup.sizes.find((size: any) => size.sizeId === row.selectedSize.sizeId)?.attributes;

    if (selectedSizeAttributes) {
      return selectedSizeAttributes.map((attribute: any) => {
        let overrideValue;
        row.selectedAdjustments.forEach((adjustment: any) => {
          let match = attribute.overrides.find((override: any) => override.adjustmentId === adjustment.adjustmentId)?.value;
          if (match) {
            overrideValue = match;
          }
        });

        return {
          name: attribute.name,
          value: overrideValue || attribute.value,
        };
      });
    }
    return null;
  }

  handleQuantityChange(row: any, index: any) {
    if (row.quantity < 1) {
      row.quantity = 1;
    }
    if (this.productSizeInfo.extras.length && row.quantity > 2500) {
      row.quantity = 2500;
    }

    if (this.productSizeInfo.extras.length) {
      let extraQuantity = row.extraCustomizations.length / this.productSizeInfo.extras.length;
      if (row.quantity > extraQuantity) {
        for (let i = extraQuantity; i < row.quantity; i++) {
          this.productSizeInfo.extras.forEach((extra: any) => {
            row.extraCustomizations.push({
              extraId: extra.id,
              value: "",
              row: i,
              extraName: extra.name,
              customizationTextMaximumLength: extra.customizationTextMaximumLength,
            });
          });
        }
      } else if (row.quantity < extraQuantity) {
        row.extraCustomizations.splice(row.quantity * this.productSizeInfo.extras.length);
      }
    }
    this.determineCosts(this.product.id || this.product.customizedProduct.id);
  }

  closeWindow() {
    this.handleClose();
    this.$emit("close");
  }

  async selectItem(item: OrderCartItemViewModel) {
    this.selectedProduct = item;
    this.getSizingInfo(this.selectedProduct.customizedProduct.id);
  }

  editNamesNumbers(row: any, index: any) {
    this.showNamesNumbers = true;
    this.selectedRow = { ...row };
    this.selectedRowIndex = index;
  }

  handleClose() {
    this.showSizeInfo = false;
    this.showNamesNumbers = false;
    this.selectedRow = null;
    this.selectedRowIndex = null;
  }

  getCustomerName(row: any) {
    if (!row.orderCustomerContactId) {
      return "Unassigned / Extra";
    }

    if (this.cart.customerContacts?.length && row.orderCustomerContactId) {
      const nameFromContacts = this.cart.customerContacts.find((contact) => contact.id === row.orderCustomerContactId);
      if (nameFromContacts) {
        return `${nameFromContacts.name}${nameFromContacts.referenceNumber ? " (#" + nameFromContacts.referenceNumber + ")" : ""}`;
      } else if (row.orderCustomerContactName) {
        return row.orderCustomerContactName;
      }
      return null;
    }
  }

  addRow(quantity: number) {
    let defaultAdjustments = [] as Array<any>;
    this.productSizeInfo.adjustmentTypes.forEach((adjustment: any) => {
      defaultAdjustments.push(adjustment.adjustments[0]);
    });

    if (this.productSizeInfo.extras.length) {
      let extras = [] as Array<any>;

      for (let i = 0; i < 1; i++) {
        this.productSizeInfo.extras.forEach((extra: any) => {
          extras.push({
            extraId: extra.id,
            value: "",
            row: i,
            extraName: extra.name,
            customizationTextMaximumLength: extra.customizationTextMaximumLength,
          });
        });
      }
      this.quantitiesArray.push({
        quantity: quantity,
        orderCustomerContactId: null,
        orderCustomerContactName: null,
        selectedSizeGroup: this.productSizeInfo.sizeGroups[0],
        selectedSize: this.productSizeInfo.sizeGroups[0].sizes[0],
        selectedAdjustments: defaultAdjustments,
        extraCustomizations: [...extras],
      });
    } else {
      this.quantitiesArray.push({
        quantity: quantity,
        orderCustomerContactId: null,
        orderCustomerContactName: null,
        selectedSizeGroup: this.productSizeInfo.sizeGroups[0],
        selectedSize: this.productSizeInfo.sizeGroups[0].sizes[0],
        selectedAdjustments: defaultAdjustments,
        extraCustomizations: [],
      });
    }

    this.determineCosts(this.product.id || this.product.customizedProduct.id);
  }

  removeRow(row: any, index: number) {
    this.quantitiesArray.splice(index, 1);

    if (!this.quantitiesArray.length) {
      this.addRow(1);
    }

    this.determineCosts(this.product.id || this.product.customizedProduct.id);
  }

  getSizingInfo(id: string) {
    this.loading = true;
    CustomizedProducts.customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(id)
      .then((res) => {
        if (res.data.succeeded) {
          this.productSizeInfo = res.data.resultData as CustomizedProductSizeOptionsViewModel;

          if (Object.prototype.hasOwnProperty.call(this.selectedProduct, "quantities") && this.selectedProduct.quantities.length) {
            this.quantitiesArray = [];
            this.selectedProduct.quantities.forEach((group: OrderCartItemQuantityViewModel) => {
              let sizeGroup = this.productSizeInfo.sizeGroups.find((item: any) => {
                return item.sizeGroupId === group.sizeGroupId;
              });

              let size = sizeGroup?.sizes.find((item: any) => {
                return item.sizeId === group.sizeId;
              });

              if (!size) {
                // this.selectedProduct.quantities = [];
                // this.addRow(this.product.customizedProduct.quantity || 1);
                this.showSizeError = true;
                // this.getSizingInfo(this.selectedProduct.customizedProduct.id);
              } else {
                let adjustments = [] as Array<any>;
                let extras = [] as Array<any>;

                //find selected adjustments for each adjustment type in each quantity group
                this.productSizeInfo.adjustmentTypes.forEach((type: any) => {
                  type.adjustments.forEach((item: any) => {
                    group.adjustments.forEach((groupAdj: any) => {
                      if (item.adjustmentId === groupAdj.adjustmentId) {
                        adjustments.push(item);
                      }
                    });
                  });
                });

                if (this.productSizeInfo.extras.length) {
                  //go through each extra that can exist on the product
                  this.productSizeInfo.extras.forEach((item) => {
                    //go through each row
                    for (let i = 0; i < group.quantity; i++) {
                      //get the extra customization for this row and extra
                      let extra = group.extraCustomizations.find((x) => x.row == i && x.extraId == item.id);
                      if (extra) {
                        //if it exists push it with the existing value
                        extras.push({
                          extraId: extra.extraId,
                          value: extra.value,
                          row: extra.row,
                          extraName: extra.extraName,
                          customizationTextMaximumLength: item.customizationTextMaximumLength,
                        });
                      } else {
                        //if it doesn't push a new one with an empty value
                        extras.push({
                          extraId: item.id,
                          value: "",
                          row: i,
                          extraName: item.name,
                          customizationTextMaximumLength: item.customizationTextMaximumLength,
                        });
                      }
                      //if it still doesn't work blame Liam -> it didnt work Liam but it was an easy fix -> I take that back, had to do a bit more fixing :)
                    }
                  });
                }

                //order the extras by row
                extras = extras.sort((a: any, b: any) => {
                  return a.row - b.row;
                });

                this.quantitiesArray.push({
                  orderCustomerContactId: group.orderCustomerContactId,
                  orderCustomerContactName: group.orderCustomerContactName,
                  quantity: group.quantity,
                  selectedSizeGroup: sizeGroup,
                  selectedSize: size,
                  selectedAdjustments: [...adjustments],
                  extraCustomizations: [...extras],
                });
              }
            });

            if(this.showSizeError) {
              this.selectedProduct.quantities = this.quantitiesArray = [];
              this.addRow(this.product.customizedProduct.defaultQuantity || 1);
              this.getSizingInfo(this.selectedProduct.customizedProduct.id);
            }
          } else if (this.quantitiesArray[0].selectedSizeGroup && !this.quantitiesArray[0].selectedSizeGroup.sizeGroupId) {
            this.quantitiesArray[0].quantity = this.quantity;
            this.quantitiesArray[0].selectedSizeGroup = this.productSizeInfo.sizeGroups[0] as any;
            this.productSizeInfo.adjustmentTypes.forEach((adjustment: any) => {
              this.quantitiesArray[0].selectedAdjustments.push(adjustment.adjustments[0]);
            });

            if (this.productSizeInfo.extras.length) {
              let extras = [] as Array<any>;

              for (let i = 0; i < this.quantity; i++) {
                this.productSizeInfo.extras.forEach((extra: any) => {
                  extras.push({
                    extraId: extra.id,
                    value: "",
                    row: i,
                    extraName: extra.name,
                    customizationTextMaximumLength: extra.customizationTextMaximumLength,
                  });
                });
              }

              this.quantitiesArray[0].extraCustomizations = extras;
            }

            this.defaultSizeforSizeGroup(this.quantitiesArray[0]);
          }
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  defaultSizeforSizeGroup(row: any) {
    if (row.selectedSizeGroup.sizeGroupId) {
      row.selectedSize = row.selectedSizeGroup.sizes[0];
    }
  }

  determineCosts(id: string) {
    if (id && this.country().id && !this.inCart && !this.isStoreCustomer) {
      this.costQuantity = this.totalQuantity;
      this.loading = true;
      CartItems.cartItemsCalculatePriceForCustomizedProductsPost(this.country().id, false, [{ customizedProductId: id, quantity: this.costQuantity }])
        .then((res) => {
          if (res.data.succeeded) {
            this.priceInfo = res.data.resultData as CustomizedProductPriceViewModelApplicationResultResultData;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    }
  }

  handleClick() {
    this.quantities = [];
    this.quantitiesArray.forEach((quantity: any) => {
      let adjustments = [] as Array<any>;

      quantity.selectedAdjustments.forEach((adjustment: any) => {
        adjustments.push({
          adjustmentId: adjustment.adjustmentId,
          adjustmentName: adjustment.name,
        });
      });

      this.quantities.push({
        orderCustomerContactId: quantity.orderCustomerContactId,
        quantity: quantity.quantity,
        sizeId: quantity.selectedSize.sizeId,
        adjustments: adjustments, //adjustmentId strings
        extraCustomizations: [...quantity.extraCustomizations],
      });
    });

    if (!this.isStoreCustomer && this.inCart && this.totalQuantity < (this.selectedProduct.customizedProduct.minimumQuantity as number)) {
      this.$notify({ type: "error", text: `The minimum quantity for this product is ${this.selectedProduct.customizedProduct.minimumQuantity} units.`, ignoreDuplicates: true, duration: -1 });
      return;
    } else if (this.inCart) {
      this.loading = true;
      const accessKey = (this.$route.params.accessKey as string) || undefined;

      CartItems.cartItemsUpdateCartItemIdPut(this.selectedProduct.id, accessKey, { quantities: this.quantities })
        .then((res) => {
          if (res.data.succeeded) {
            this.resetValues();
            this.$emit("update");
            this.$emit("close");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    } else if (this.isStoreCustomer) {
      this.loading = true;
      CartItems.cartItemsUpdateStoreCartItemCartIdCartItemIdPut(this.cart.id, this.product.id, { quantities: this.quantities })
        .then((res) => {
          if (res.data.succeeded) {
            this.resetValues();
            this.$emit("update");
            this.$emit("close");
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    } else {
      this.$emit("checkout", this.quantities);
    }
  }

  resetValues() {
    this.quantities = [
      {
        quantity: this.quantity,
        sizeId: "",
        adjustments: [], //adjustmentId strings
        extraCustomizations: [],
      },
    ];
    this.quantitiesArray = [
      {
        orderCustomerContactId: null,
        orderCustomerContactName: null,
        quantity: this.quantity,
        selectedSizeGroup: {
          sizeGroupId: "",
          displayName: "",
          sizes: [],
        },
        selectedSize: {
          sizeId: "",
          name: "",
        },
        selectedAdjustments: [],
      },
    ];
    this.productSizeInfo = {
      sizeGroups: [],
      adjustmentTypes: [],
      extras: [],
    };
    this.editCustomer = false;
  }

  beforeUnmount() {
    clearTimeout(this.timeout);
  }
}
