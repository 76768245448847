
import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import  countries from '@/assets/country-codes/countries.json'
import { DeliveryType, OrderCartViewModel, OrderStatus } from "@/api-client";

@Options({
  components: { Form, Field, ErrorMessage },
  props: {
    cart: { default: null },
    current: String
  },
  emits: ["update"]
})
export default class CheckoutUserBillingComponent extends Vue {
  countryCodes = countries;
  selectedDialCode = '+1';
  phoneNumber = '';
  socialEnabled = true;
  user = {
    email: '',
    password: ''
  }
  guestUser = {
    email: ''
  }
  cart: OrderCartViewModel = {
    id: '',
    items: [],
    countryId: '',
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: '',
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    orderDocuments: [],
    isQuote: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    referenceNumber: '',
    deliveryAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    billingAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    customerContact: {
      id: '',
      name: '',
      email: '',
      phoneNumber: ''
    },
    accessKey: '',
    allowedPaymentProviders: [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  }
  cartDetails: OrderCartViewModel = {
    id: '',
    items: [],
    countryId: '',
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: '',
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    orderDocuments: [],
    isQuote: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    referenceNumber: '',
    deliveryAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    billingAddress: {
      id: '',
      addressLine1:	'',
      area:	'',
      country:	'',
      postalCode:	''
    },
    customerContact: {
      id: '',
      name: '',
      email: '',
      phoneNumber: ''
    },
    accessKey: '',
    allowedPaymentProviders: [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  }

  get sortedDialCodes() {
    return this.countryCodes.sort((a:any, b:any) => {
      return a.dialCode - b.dialCode;
    })
  }

  created() {
    this.$watch('cart', () => {
      if(this.cart.id !== '') {
        this.cartDetails = {...this.cart}
        if(!this.cartDetails.deliveryAddress) {
          this.cartDetails.deliveryAddress = {
            id: '',
            companyName: '',
            addressLine1:	'',
            addressLine2: '',
            area:	'',
            country:	'',
            postalCode:	'',
            additionalNotes: '',
            ponumber: ''
          }
        }
        if(!this.cartDetails.billingAddress) {
          this.cartDetails.billingAddress = {
            id: '',
            companyName: '',
            addressLine1:	'',
            addressLine2: '',
            area:	'',
            country:	'',
            postalCode:	'',
            additionalNotes: '',
            ponumber: ''
          }
        }
        if(!this.cartDetails.customerContact) {
          this.cartDetails.customerContact = {
            id: '',
          name: '',
          email: '',
          phoneNumber: ''
        }
        } 
        if(this.cartDetails.customerContact.phoneNumber && (this.cartDetails.customerContact.phoneNumber !== '')) {
          let array = this.cartDetails.customerContact.phoneNumber.split('-')
          if (array.length >= 2) {
            this.selectedDialCode = array[0];
            array.shift()
            this.phoneNumber = array.join('-');
          }
        }
        console.log('dets',this.cart, this.cartDetails);
      }
    })
  }

  guestSignIn(values: any) {
    console.log(values);
  }

  updateDetails() {
    let numberWithCode = `${this.selectedDialCode}-${this.phoneNumber}`;
    let detailsToSubmit = { ...this.cartDetails };
    detailsToSubmit.customerContact!.phoneNumber = numberWithCode;

    this.$emit('update', detailsToSubmit);
    console.log(detailsToSubmit);
  }
}
