
import { mixins, Options, Vue } from "vue-class-component";
import OrderProductTile from "@/components/profile/order-history/OrderProductTile.vue";
import { CalculatePrice, FormatDate, ThousandSeparator } from "@/mixins/utilities";
import { OrderHistoryViewModel, OrderItemSizeExtraCustomizationHistoryViewModel, OrderPublicStatus, StoreStatus, StoreViewModel } from "@/api-client";
import { Orders, Stores } from "@/network/api";
import Spinner from "@/components/spinner/Spinner.vue";
import { ScrollTop } from "@/mixins/utilities";

@Options({
  components: { OrderProductTile, Spinner },
  props: {
    order: { default: "" },
    pageName: { default: "Profile" },
    final: { default: false }
  },
})
export default class OrderStoreRecipients extends mixins(CalculatePrice, ThousandSeparator, FormatDate, ScrollTop) {
  orderId: any = "";
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  orderClone: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    marketingOptIn: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "de7c7489-fcc5-40d4-ad7c-f9de27a97f18",
      name: "Pound Sterling",
      code: "GBP",
      symbol: "£",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  loading = false;
  pageName = "Profile";
  byCustomer = true;
  store: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };

  created() {
    if (this.order) {
      this.orderClone = { ...this.order };
      if(this.orderClone.storeId) {
        this.getStoreInfo()
      }
    } else if (!this.order && this.$route.query.order) {
      this.orderId = this.$route.query.order;
      this.loadInitialData();
    }
  }

  mounted() {
    if (this.$route.query.recipients === "all") {
      this.byCustomer = true;
    } else {
      this.byCustomer = false;
    }
  }

  topAnchor() {
    this.scrollTop(`.${this.pageName.toLowerCase()}-content-container`, false);
  }

  printTable() {
    window.print();
  }

  async getStoreInfo() {
    this.loading = true;
    await Stores.storesIdGet(this.orderClone.storeId as string)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.store = res.data.resultData as StoreViewModel;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  loadInitialData() {
    if (this.orderId) {
      this.loading = true;
      Orders.ordersGetOrderOrderIdGet(this.orderId)
        .then(async (res) => {
          if (res.data.succeeded) {
            this.orderClone = res.data.resultData as any;

            if(this.orderClone.storeId) {
              await this.getStoreInfo()
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          console.log(error);
        });
    }
  }
}
