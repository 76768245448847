
import { mixins, Options } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import OrderSummary from "@/components/checkout-process/checkout/OrderSummary.vue";
import { IsLoggedIn, QuantityPerItem } from "@/mixins/utilities";
import { loadStripe } from "@stripe/stripe-js";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { Orders, Payments } from "@/network/api";
import { EftBankDetails, OrderHistoryViewModel, OrderOutstandingPaymentViewModel, OrderPublicStatus } from "@/api-client";
import CompleteOutstandingPaymentSummary from "@/components/checkout-process/payment-complete/CompleteOutstandingPaymentSummary.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { marked } from "marked";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: { Spinner, HeadingBanner, OrderSummary, Breadcrumbs, CompleteOutstandingPaymentSummary, SetMetaData },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
})
export default class OutstandingPaymentCompletePage extends mixins(IsLoggedIn, QuantityPerItem, SetMetaData) {
  id = "";
  stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  stripe: any;
  stripeLoaded: any;
  order: OrderHistoryViewModel = {
    id: "",
    referenceNumber: "",
    orderPublicStatusId: OrderPublicStatus.Processing,
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    marketingOptIn: false,
    showOrderPackApprovalLink: false,
    showAssetResupplyLink: false,
    showOutstandingPaymentLink: false,
    additionalCostsConvertedTotal: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    orderTotal: 0,
    orderTotalIncludingVoucher: 0,
    orderItems: [],
    deliveryTotal: 0,
    adhocTotal: 0,
    orderInvoices: [],
    customerContacts: [],
    paymentsConvertedTotal: 0,
    paymentsTotal: 0
  };
  loading = false;
  bankDetails: EftBankDetails = {
    bankTransferDetails: '',
    reference: ''
  };
  isBankTransfer = false;
  outstandingPayment: OrderOutstandingPaymentViewModel = {
    id: "",
    referenceNumber: "",
    outstandingPaymentValue: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    outstandingPaymentConvertedValue: 0,
  };
  orderLoaded = false

  messages: Array<string> = [];

  clientSecret = "";

  get formattedBankDetails() {
    if (this.isBankTransfer && this.bankDetails.bankTransferDetails) {
      let markedDetails = marked(this.bankDetails.bankTransferDetails)
      markedDetails = markedDetails.replace('<p>', "");
      markedDetails = markedDetails.replace('</p>', "");
      markedDetails = markedDetails.replaceAll('<strong>', '<div class="d-flex align-items-baseline col-12"><h6 class="bank-dets-headings m-0 me-2">');
      markedDetails = markedDetails.replaceAll('</strong>', '</h6>');
      markedDetails = markedDetails.replaceAll('<em>', '<p class="bank-dets-info m-0 mb-1">');
      markedDetails = markedDetails.replaceAll('</em>', '</p></div>');
      return markedDetails;
    }
    return "";
  }

  get title() {
    if(!this.order.id && !this.loading) {
      return "";
    } else if(!this.isBankTransfer) {
      return "Payment Complete";
    } else {
      return "Payment Pending";
    }
  }

  async beforeMount() {
    const route = useRoute();
    debugger
    if(!route.query.eft) {

    if (route.query["payment_intent_client_secret"]) {
      this.clientSecret = route.query["payment_intent_client_secret"].toString();
    }

    const stripePromise = loadStripe(this.stripeKey as string);
    stripePromise.then(async (stripe) => {
      this.stripe = stripe;
      this.stripeLoaded = true;

      const { error, paymentIntent } = await stripe!.retrievePaymentIntent(this.clientSecret);

      if (error) {
        this.messages.push(error.message!);
      }
      this.messages.push(`Payment ${paymentIntent!.status}: ${paymentIntent!.id}`);
    });
    }
  }

  created() {
    this.orderLoaded = false

    if (this.$route.query.eft) {
      this.isBankTransfer = true;
    }

    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', `${this.isBankTransfer ? 'Payment Pending' : 'Payment Complete'} | `)

    this.loadInitialData();
  }

  // printSummary() {
  //   let allRefs = this.$refs as any

  //     var data = allRefs.summary.innerHTML;
  //     debugger
  //     var myWindow = window.open('', 'my div', 'height=400,width=600') as any;
  //     myWindow.document.write('<html><head><title>my div</title>');
  //     /*optional stylesheet*/ //myWindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
  //     myWindow.document.write('</head><body >');
  //     myWindow.document.write(data);
  //     myWindow.document.write('</body></html>');
  //     myWindow.document.close(); // necessary for IE >= 10

  //     myWindow.onload=function(){ // necessary if the div contain images
  //         myWindow.focus(); // necessary for IE >= 10
  //         myWindow.print();
  //         myWindow.close();
  //     };
  // }

  async loadInitialData() {
    this.orderLoaded = false
    if (this.id) {
      this.loading = true;
      await Orders.ordersGetOrderOrderIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.order = res.data.resultData as OrderHistoryViewModel;
            this.orderLoaded = true
          }
        })
        .catch((error) => {
          console.log(error)
          this.orderLoaded = true
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage });
          });
          console.log(error);
        });

      if(this.isBankTransfer) {
        Orders.ordersGetOrderOutstandingPaymentOrderIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.outstandingPayment = res.data.resultData as OrderOutstandingPaymentViewModel;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if(error.response.status == 401) {
            this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
            this.$router.push({ name: 'Login', query: { returnUrl: this.$route.fullPath }});
          } else {
            let errors = error.response.data.errors;
            errors.forEach((error: any) => {
              if(error.friendlyMessage == 'You do not have access to this order') {
                this.$notify({ type: "error", text: 'Please log in to the relevant account to view this page.', ignoreDuplicates: true, duration: -1 });
                this.$router.push({ name: 'Login', query: { returnUrl: this.$route.fullPath }});
              }
              this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
            });
          }
        });

        
          await Payments.paymentsGetEftBankDetailsIdGet(this.id)
          .then((res) => {
            if (res.data.succeeded) {
              this.bankDetails = res.data.resultData as EftBankDetails;
            }
          })
          .catch((error) => {
            console.log(error)
            this.loading = false;
            let errors = error.response.data.errors;
            errors.forEach((error: any) => {
              this.$notify({ type: "error", text: error.friendlyMessage });
            });
            console.log(error);
          });
        }

        this.loading = false;
    }
  }
}
